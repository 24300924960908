export const extendInfoColumns = () => {
  return [
    {
      title: '序号',
      width: '40px',
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '扩展名称',
      width: '100px',
      dataIndex: 'ikey',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '扩展值',
      width: '120px',
      dataIndex: 'value',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '操作',
      width: '150px',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}